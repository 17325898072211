import React, { useContext, useState } from 'react';

import { some } from 'lodash';
import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import MembersList from '@domui-presenters/web/pages/Districts/Members/MembersList';
import PageSteps from '@components/PageSteps';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Filters from '@domui-presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@domui-presenters/web/components/DistrictMembersFilters/types';
import Heading from '@domui-presenters/web/components/DistrictSelectMemberStep/Heading';
import MemberItem from './MemberItem';
import { processMembers } from '@domui-presenters/web/pages/Districts/Members/DistrictMembers';
import {
  CommitteeAppointment,
  CommitteeMemberData,
  DistrictMember,
  OperationType,
  Step,
} from '@domui-domain/districts';
import {
  DistrictContext,
  getBackUrl,
  getMemberTypesOptions,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useFetchMembersWithFilters } from '@domui-repositories/districts/hooks/useFetchMembersWithFilters';
import { useDistrictMembersFilters } from '@domui-use-cases/districts/hooks/useDistrictMembersFilters';
import { useLocalStorage } from '@repositories/storage/hooks';
import { localizedNavigate } from '@utils/localized-navigate';
import { useTranslation } from '@external/react-i18next';
import { MembershipType } from '@typings/operations';
import { useClubOptions } from '@domui-use-cases/districts/hooks/useClubOptions';
import { useDistrictOptions } from '@domui-use-cases/districts/hooks/useDistrictOptions';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  stepCount?: number;
};

const PAGE_SIZE = 10;
const DEFAULT_STEP_COUNT = 2;
// const FUTURE_YEAR_COUNT = 1;

const TAB_DISTRICT_MEMBERS = 'District Members';
const TAB_PREVIOUS_DES = 'Previous District Executive Secretary';

export type SelectMemberTabs =
  | typeof TAB_DISTRICT_MEMBERS
  | typeof TAB_PREVIOUS_DES;

const SelectMemberStep: React.FC<Props> = ({
  districtId,
  // riDistrictId,
  stepCount = DEFAULT_STEP_COUNT,
}) => {
  const { t } = useTranslation();
  const [context, setContext] = useContext(DistrictContext);
  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: TAB_DISTRICT_MEMBERS,
    scrollTo: 0,
  });

  const { clearStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    selectedValues: searchValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useDistrictMembersFilters(districtId);

  const {
    districtMembersSearchData: data,
    loading,
  } = useFetchMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    searchValues
  );

  const {
    operationType,
    selectedInfo: { role, unassignFrom },
    step,
  } = context;

  const { options: roleOptions } = useClubOptions(districtId);
  const { options: districtOptions } = useDistrictOptions(districtId);

  const memberTypesOptions = getMemberTypesOptions(t);
  const multiSelectOptions = {
    clubs: roleOptions,
    memberTypes: memberTypesOptions,
    districtRoles: districtOptions,
  };

  useStopBrowserNavigate({
    isNextStepVisited: true,
    onNavigate: () => handleStepBack(),
  });

  if (loading) {
    return <Loading />;
  }

  if (!data || !data?.districtMembersDetails) {
    return null;
  }

  const members = data?.districtMembersDetails;
  const processedMembers = processMembers(members);
  const totalCount = data?.totalCount || 0;

  const handlePagination = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const handleSelectAssignee = (
    assignee: DistrictMember | CommitteeMemberData
  ) => {
    window.localStorage.removeItem('isNextStepVisited');
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step + 1) as Step,
      selectedInfo: {
        ...prevState.selectedInfo,
        assignee,
      },
    }));
    window.scrollTo(0, 0);
  };

  const handleStepBack = () => {
    window.localStorage.setItem('isNextStepVisited', 'true');
    if (step === 1) {
      clearStorage();
      localizedNavigate(getBackUrl(operationType, districtId));
      return;
    }
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step - 1) as Step,
    }));
    window.scrollTo(0, 0);
  };

  const { id, photoUri, nameWithPrefixSuffix } = unassignFrom || {};

  const isReplace = operationType === OperationType.REPLACE_OFFICER;
  const isAssign = operationType === OperationType.ASSIGN_TO_ROLE;

  // const isAssignToMember = operationType === OperationType.ASSIGN_TO_MEMBER;

  const isAssignOrReplace = isReplace || isAssign;
  // const areTabsMultiple = isDES(role?.name);

  const isRotaractorFilterApplied = some(
    filterValues.filters.memberTypes,
    memberType => memberType.value === MembershipType.Rotaractor
  );

  const isMemberFilterApplied = some(
    filterValues.filters.memberTypes,
    memberType => memberType.value === MembershipType.Member
  );

  const isFilterApplied: { [key in MembershipType]?: boolean } = {
    [MembershipType.Rotaractor]: isRotaractorFilterApplied,
    [MembershipType.Member]: isMemberFilterApplied,
  };

  const membersTab = {
    name: TAB_DISTRICT_MEMBERS,
    title: `${t(
      'district-management.district-members',
      'District members'
    )} (${totalCount})`,
    component: (
      <MembersList
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={totalCount}
        members={(processedMembers as DistrictMember[]) || []}
        memberItem={member => (
          <MemberItem
            member={member}
            selectHandler={handleSelectAssignee}
            isFilterApplied={isFilterApplied}
          />
        )}
        // areTabsMultiple={areTabsMultiple}
        filters={
          <Filters
            initialFilterValues={searchValues}
            multiSelectOptions={multiSelectOptions}
            applyFilter={handleApplyFilters}
            resetFilter={handleResetFilters}
            filtersListToShow={[
              MEMBER_FILTERS.CLUB_IDS,
              MEMBER_FILTERS.MEMBER_TYPES,
            ]}
          />
        }
        showFiltersInContextMenu
        // headerClassName={classNames('-m-2', { 'pr-14': areTabsMultiple })}
      />
    ),
  };

  const tabs = [membersTab];

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      to={isAssignOrReplace ? getBackUrl(operationType, districtId) : undefined}
      backHandler={isAssignOrReplace ? undefined : handleStepBack}
      step={step}
      total={stepCount}
      navClassName="mt-6 mb-0 text-small font-bold"
    >
      <Heading
        roleTitle={role?.name || ''}
        title={
          isReplace
            ? t('select-member-to-replace.title', 'Select a Member to Replace')
            : t('select-member.title', 'Select a Member')
        }
        subTitle={
          isReplace ? (
            <IndividualListColumn
              fullName={nameWithPrefixSuffix || ''}
              photoUri={photoUri || ''}
              id={id || ''}
              roleTitle={`${t('global.as', 'as')} ${role?.name}`}
              associatedClubsInfo={[]}
            />
          ) : null
        }
      />
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
      />
      <TableWithTabs
        activeTabInfo={activeTab}
        tabs={tabs}
        onChangeTab={setActiveTab}
      />
    </PageSteps>
  );
};

export default SelectMemberStep;
