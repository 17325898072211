import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from '@reach/router';
import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';
import Confirmation from '@presenters/web/pages/Districts/CommitteeAppointments/Confirmation';

import {
  CommitteeAppointment,
  isAG,
  OperationType,
  Step,
  TermToAssign,
} from '@domui-domain/districts';

import {
  buildAGsClubAssignmentsUrl,
  defaultContextState,
  filterDistrictLeadershipByRoles,
  getBackUrl,
  getFormattedStartDate,
  getIsAGPAge,
  getTermByYear,
  isCurrentTerm,
  // useModal,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useModal } from '@use-cases/districts';

import { useNotifications } from '@use-cases/notifications';

import {
  useFetchIsAgInfoById,
  useUpdateAGClubAffiliations,
} from '@repositories/districts';
import { useManageDistrictLeadership } from '@domui-repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { pushLocalisedUrl } from '@utils/browserHistory';
import { getRotaryYear } from '@domui-utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { DistrictContext } from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

const ConfirmDetails = ({ riDistrictId }: { riDistrictId?: Maybe<number> }) => {
  const { t } = useTranslation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBack, setIsBack] = useState(false);
  const { addError, addSuccess } = useNotifications();
  const location = useLocation();
  const [context, setContext] = useContext(DistrictContext);

  const {
    operationType,
    districtId,
    step,
    selectedInfo: { role, assignee, term, unassignFrom },
    committeeManager: { isManager },
  } = context;

  const { updateStorage, getStorageData } = useLocalStorage<
    CommitteeAppointment
  >();

  const isReassign = operationType === OperationType.REASSIGN_TO_AG;

  const resetContext = () => {
    const newValues = {
      ...context,
      operationType: null,
      step: 1 as Step,
      selectedInfo: {
        ...context.selectedInfo,
        role: defaultContextState.selectedInfo.role,
        assignee: defaultContextState.selectedInfo.assignee,
        unassignFrom: defaultContextState.selectedInfo.unassignFrom,
        term: isReassign ? context.selectedInfo.term : context.termsToAssign[0],
      },
    };
    updateStorage(newValues);
  };

  const { data: storageData } = getStorageData('backUrl') || {};
  const storageDataBackUrl = storageData?.backUrl;

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;

  const backUrl =
    backUrlFromState ||
    storageDataBackUrl ||
    getBackUrl(operationType, districtId);

  const isCurrentTermSelected = isCurrentTerm(term);
  const assignDate = moment(
    isCurrentTermSelected
      ? undefined
      : getFormattedStartDate(term?.startDate || '')
  );

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    setIsBack,
    isStepMax: true,
    backUrl,
  });

  const isReplace = [
    OperationType.REPLACE_AG,
    OperationType.REPLACE_ROLE,
  ].includes(operationType as OperationType);

  const {
    assignDL,
    updateDLForFutureRY,
    updateDLForCurrentRY,
    replaceClubAffiliationsForFutureRY,
    isLoading,
  } = useManageDistrictLeadership({
    districtId,
    riDistrictId,
  });

  const [
    updateAGClubAffiliations,
    { loading: isUpdateClubAffiliationLoading },
  ] = useUpdateAGClubAffiliations();

  const yearNow = Number(getRotaryYear());

  const {
    loading: isAssignedToAgLoading,
    ...termsAvailabilityInfo
  } = useFetchIsAgInfoById(
    // do not make call if it is not reassign
    isReassign ? assignee?.id : undefined,
    String(riDistrictId),
    isManager
  );

  const getTodayFormatted = () => moment().format('YYYY-MM-DD');

  const getNowTerms = ({
    nextYearAssigned,
    currentYearAssigned,
  }: {
    nextYearAssigned: boolean;
    currentYearAssigned: boolean;
  }) => {
    const { nowTerm, afterNowTerm } = isManager;
    const termForNowYear =
      nowTerm && !currentYearAssigned ? getTermByYear(yearNow) : null;
    const termForElect =
      afterNowTerm && !nextYearAssigned ? getTermByYear(yearNow + 1) : null;

    return { termForNowYear, termForElect };
  };

  const [termToReassign, setTermToReassign] = useState<Maybe<TermToAssign>>(
    null
  );

  useEffect(() => {
    const { termForNowYear, termForElect } = getNowTerms(termsAvailabilityInfo);
    const termToSelect = termForNowYear || termForElect;
    const isBothTermsAvailable = termForNowYear && termForElect;
    // If termToReassign is not equal to termToSelect, we need to assign
    // If both terms are available - we do not to trigger `setState`
    if (
      !termToReassign ||
      (!isBothTermsAvailable &&
        termToReassign.startDate !== termToSelect?.startDate)
    ) {
      setTermToReassign(termToSelect);
    }
  }, [termToReassign, termsAvailabilityInfo]);

  const handleAPICallFailure = () =>
    addError(
      t(
        'assign-member.confirmation.error-request',
        'An error occurred assigning your role'
      )
    );

  const getTotalStepCount = () => {
    if (isReplace) {
      const filteredLeaderships = filterDistrictLeadershipByRoles(
        unassignFrom?.thisDistrictLeadership || []
      );
      // we will have length > 1 only from members page,
      // if member holds multiple roles
      if (filteredLeaderships.length > 1) {
        return 4;
      }
    }
    return 3;
  };

  const handleAPICallSuccess = (role: string, name?: string) => {
    const stepsCount = getTotalStepCount();

    addSuccess(
      t(
        'assign-officer.confirmation.success',
        'Success! {{officerFullName}} has been assigned the role of {{role}}.',
        {
          officerFullName: name,
          role,
        }
      )
    );
    resetContext();
    localizedNavigate(buildAGsClubAssignmentsUrl(districtId), {
      state: {
        step: stepsCount,
        stepsCount,
        term: isReassign ? termToReassign : term,
        backUrl,
      },
    });
  };

  const handleBackHandler = () => {
    setGlobal(false);
    if (
      isBack &&
      !isReassign &&
      (isReplace ||
        getIsAGPAge(location) ||
        (isManager.nextTerm && isManager.currentTerm))
    ) {
      const isAssignAndNotAGPage =
        operationType === OperationType.ASSIGN_TO_AG && !getIsAGPAge(location);
      setContext(prevState => ({
        ...prevState,
        step: (step - 1) as Step,
        selectedInfo: {
          ...prevState.selectedInfo,
          assignee: isAssignAndNotAGPage
            ? prevState.selectedInfo.assignee
            : null,
        },
      }));
      window.scrollTo(0, 0);
      return;
    }
    setIsBack(false);
    resetContext();
    localizedNavigate(backUrl);
  };

  const replaceAGForCurrentRY = async (
    leadershipId: string,
    assigneeId: string
  ) => updateDLForCurrentRY(leadershipId, assigneeId, assignDate);

  const replaceAGForFutureRY = async (leadershipId: string) =>
    updateDLForFutureRY(leadershipId, assignDate);

  const handleSubmit = async () => {
    try {
      if (role && assignee) {
        const assigneeId = assignee.id;
        const roleId = role.id;

        // if current term, then use today
        // otherwise, make startDate for the future year
        const getAssignDate = (selectedTerm: Maybe<TermToAssign>) =>
          isCurrentTerm(selectedTerm)
            ? getTodayFormatted()
            : getFormattedStartDate(selectedTerm?.startDate || '');

        const assignDate = getAssignDate(isReassign ? termToReassign : term);

        if (!isReplace) {
          await assignDL(assigneeId, roleId, assignDate);
        }
        if (isReplace && unassignFrom) {
          const leadershipId = unassignFrom.thisDistrictLeadership.find(
            ({ roleId }) => isAG(roleId)
          )?.id;

          if (isCurrentTermSelected) {
            await updateAGClubAffiliations({
              variables: {
                districtId,
                individualId: unassignFrom.id,
                newIndividualId: assigneeId,
                startDate: assignDate,
              },
            });

            await replaceAGForCurrentRY(leadershipId || '', assigneeId);
          } else {
            await replaceClubAffiliationsForFutureRY(
              unassignFrom.id,
              assigneeId,
              assignDate
            );

            await replaceAGForFutureRY(leadershipId || '');
            await assignDL(assigneeId, role!.id, assignDate);
          }
        }
        handleAPICallSuccess(role.name, assignee.name);
      }
    } catch {
      handleAPICallFailure();
    } finally {
      setGlobal(false);
      pushLocalisedUrl(backUrl);
    }
  };

  const modalOnCancelHandler = () => {
    setIsBack(false);
    show(true);
  };

  const modalBackHandler = () => {
    setIsBack(true);
    show(true);
  };

  const getLabels = () => {
    const defaultLabels = {
      termLabel: t('assign-from.term.label', 'Term'),
      roleLabel: t('assign-from.role.label', 'Role'),
      emailLabel: t('assign-from.email.label', 'Email'),
    };
    if (isReplace) {
      return {
        officerLabel: t(
          'assign-from.newly-assigned-officer.label',
          'Newly assigned officer'
        ),
        officerToBeReplacedLabel: t(
          'assign-from.officer-to-be-replaced.label',
          'Officer to be replaced'
        ),
        effectiveDateLabel: t(
          'assign-from.effective-date.label',
          'Effective Date'
        ),
        ...defaultLabels,
      };
    }
    return {
      officerLabel: t('assign-from.officer.label', 'Officer'),
      ...defaultLabels,
    };
  };

  const getProps = () => {
    const { email, nameWithPrefixSuffix } = assignee || {};
    const defaultProps = {
      title: t('confirm-role', 'Confirm the role of {{selectedRole}}', {
        selectedRole: role?.name || null,
      }),
      roleName: role?.name || null,
      assigneeEmail: email || null,
      assigneeName: nameWithPrefixSuffix || null,
      term: isReassign ? termToReassign : term,
      operationType,
      onChangeTerm: setTermToReassign,
      ...getNowTerms(termsAvailabilityInfo),
    };
    if (isReplace) {
      const { nameWithPrefixSuffix } = unassignFrom || {};
      return {
        ...defaultProps,
        officerToBeReplacedName: nameWithPrefixSuffix,
        effectiveDate: isCurrentTermSelected
          ? assignDate.format('DD MMM YYYY')
          : '',
      };
    }
    return defaultProps;
  };

  if (isLoading || isAssignedToAgLoading || isUpdateClubAffiliationLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageSteps
        className="p-0"
        backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
        backHandler={modalBackHandler}
        step={step}
        total={getTotalStepCount()}
      >
        <Confirmation {...getProps()} labels={getLabels()} />
        <ActionButtons
          onSubmit={handleSubmit}
          submitBtnLabel={t(
            'action-button.confirm-and-assign-clubs',
            'Confirm and Assign Clubs'
          )}
          cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
          onCancel={modalOnCancelHandler}
        />
      </PageSteps>
      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={handleSubmit}
        onContinue={handleBackHandler}
      />
    </>
  );
};

export default ConfirmDetails;
